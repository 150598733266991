import Vue from "vue";
import App from "./App.vue";
import UUID from "vue-uuid";
import "@/mixins/generalMixin";
import "@/mixins/validationMixin";
import "@/mixins/validationClassesMixin";

//API-REST
import axios from "axios";
import VueAxios from "vue-axios";

//App Router
import routerInstance from "@/router";

//Internazionalitation
import i18n from "./i18n";

//Bootstrap
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";

//FontAwesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhoneAlt, faCheck, faTimes, faUser, faQuestion, faAngleLeft, faLaptop, faMinus, faStoreAlt, faArrowAltCircleDown, faKeyboard, faDollarSign, faBarcode, faPlus, faIdCard, faAt, faCalendar } from "@fortawesome/free-solid-svg-icons";
library.add(faWhatsapp, faPhoneAlt, faCheck, faTimes, faUser, faQuestion, faAngleLeft, faLaptop, faMinus, faStoreAlt, faArrowAltCircleDown, faKeyboard, faDollarSign, faBarcode, faPlus, faIdCard, faAt, faCalendar);

//Environment configuration
import { setEnvConfig } from '@/config/envConfig.js'
import VueCookieBot from "@ambitiondev/vue-cookiebot-plugin";

//Constants
import analyticsIds from './config/constants/analytics'

export const bus = new Vue();

Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.use(UUID);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV == 'development';

const host = window.location.host;
const isValidEnvironment = setEnvConfig(host);
console.log("Is valid environment: " + isValidEnvironment);
console.log(process.env.NODE_ENV);
console.log(analyticsIds['cookieBotID'])
Vue.use(VueCookieBot, {
    cookieBotID: '8195d4b6-1b59-4759-a2e0-b37f719751b2'
})

if (isValidEnvironment) {
    new Vue({
        i18n,
        router: routerInstance,
        render: h => h(App)
    }).$mount("#app");
}
